<template>
    <div class="agricultural-records">
         <van-search class="search-notify" v-model="listQuery.inputsName" placeholder="请输入名称" shape="round" background="transparent"  @search="onSearch" input-align="center"/>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                v-if="!isNoData"
            >
                <van-cell  v-for="(item, index) in list" :key="index">
                    <div class="content-item">
                        <div class="top">
                            <img src="../../assets/images/plot/icon_list_staff.png"/>
                            <div class="content">
                                <p><span>投入品:</span><span>{{item.inputsName}}</span></p>
                                <p>{{item.createTime}}</p>
                            </div>
                        </div>
                        <div class="bottom">
                            <p><span>作业内容:</span><span @click="editInput(item)">{{item.context}}</span></p>
                        </div>
                    </div>
                </van-cell>
            </van-list>
            <!-- <van-cell v-else> -->
                <div class="no-data" v-else>
                    <div>
                        <img src="../../assets/images/icon_nodata.png"/>
                    </div>
                    <div>
                        <span class="no-data-text">暂无数据</span>
                    </div>
                </div>
            <!-- </van-cell> -->
        </van-pull-refresh>
        <router-link :to="{path: '/addAgricultural'}" v-if="isAdmin">
            <div class="btn-add">+</div>
        </router-link>
    </div>
</template>

<script>
import {listPageMassifWork} from '@/api/plotManagement';
import {
    getUserType, getMassifId
} from '@/utils/cookie'
export default {
    data() {
        return {
            isAdmin: true, //是否是管理员
            isNoData: false,//是否没有数据
            refreshing: false,//是否刷新
            loading: false,//是否等待
            finished: false,//是否结束
            list: [],
            listQuery: {
                inputsName: '',
                pageIndex: 1,
                pageSize: 500,
                massifId: '',
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.isAdmin = getUserType() == 1 ? true : false;
        });
        this.listQuery.massifId = getMassifId();
        this.listPageMassifWork();
    },
    methods: {
        onSearch() {
            this.listPageMassifWork();
        },
        /** 刷新 */
        onRefresh() {

        },
        /** 加载 */
        onLoad() {
            this.loading = false;
            this.refreshing = false;
        },
        /** 获取所有农事记录 */
        listPageMassifWork() {
            listPageMassifWork(this.listQuery).then(res => {
                if (res.code == 200) {
                    this.list = res.data || [];
                    this.isNoData = res.data.length > 0 ? false : true;
                }
            })
        },
        /** 编辑 */
        editInput(val) {
            this.$router.push({name: 'addAgricultural', params: val})
        }
    },
    beforeRouteEnter(to, from, next) {
        if (to.params.massifId) {
            next(vm => {
                vm.listQuery.massifId = to.params.massifId;
                vm.listQuery.massifId = getMassifId();
            })
        } else {
            next(vm => {
                
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .agricultural-records {
        padding: 14px;
        max-height: 600px;
        overflow: auto;
        .van-cell {
            margin-bottom: 10px;
            border-radius: 8px;
            .content-item {
                .top {
                    display: flex;
                    align-items: center;
                    img {
                        width: 38px;
                        height: 38px;
                        margin-right: 12px;
                    }
                    .content {
                        p {
                            text-align: left;
                        }
                        p:nth-of-type(1) {
                            font-size: 14px;
                            color: #4F4F4F;
                        }
                        p:nth-of-type(2) {
                            font-size: 12px;
                            color: #438EFD;
                        }
                    }
                }
                .bottom {
                    margin-top: 20px;
                    font-size: 14px;
                    color: #787878;
                    span:nth-of-type(1) {
                        margin-right: 8px;
                    }
                }
            }
            .no-data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }
        .no-data {
            text-align: center;
            background-color: transparent;
        }
        .btn-add {
            position: fixed;
            bottom: 70px;
            right: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 26px;
            color: #fff;
            text-align: center;
            line-height: 40px;
            background-color: #bfd9f6;
        }
    }
</style>